import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";
import ThumbSlide from "../../components/Slider/Slides/ThumSlide/ThumbSlide";
import PictureSlide from "../../components/Slider/Slides/PictureSlide/PictureSlide";
import "./Gallery.scss";

const Gallery = ({ title, mainSlides, thumbSlides, dir, id, counterClass }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const renderSlide = (slideInfo, index, length) => <PictureSlide key={slideInfo.image}
                                                                  slideInfo={slideInfo}
                                                                  length={length}
                                                                  className={counterClass ? counterClass : ''}
                                                                  placeholder={"2400"}
                                                                  useCounter={true}
                                                                  index={index} />;

  const renderThumb = (slideInfo, index, length, callback) => <ThumbSlide key={slideInfo.image}
                                                                          slideInfo={slideInfo}
                                                                          callback={callback}
                                                                          updateActive={setActiveIndex}
                                                                          activeIndex={activeIndex}
                                                                          index={index} />;

  const renderFilter = (slides, callback, ref) => <Slider slides={slides}
                                                          ref={ref}
                                                          className={"slider-thumb"}
                                                          callback={callback}
                                                          preloadImages={false}
                                                          renderSlide={renderThumb}
                                                          useControls={false}
                                                          dir={dir}
                                                          config={{
                                                            slidesPerView: 5,
                                                            spaceBetween: 0,
                                                            preloadImages: false
                                                          }} />;
  return (
    <section id={id} className={"pp-section pp-section-gallery pp-section-gallery-control"} dir={dir}>
      <div className={"container"}>
        <TitleBlock title={title || "gallery.title"}
                    className={"center"} />
      </div>
      <div className={"container slider-container"}>
        <Slider className={"slider-gallery"}
                renderFilter={renderFilter}
                filtersSlides={thumbSlides}
                useFilter={true}
                preloadImages={false}
                slides={mainSlides}
                onChange={setActiveIndex}
                renderSlide={renderSlide}
                prevEl={"gallery-button-prev"}
                nextEl={"gallery-button-next"}
                useCustomControls={true}
                paginationClass={""}
                useDynamicBullets={false}
                dir={dir}
                config={{ slidesPerView: 1, spaceBetween: 0, preloadImages: false, autoHeight: true }} />
      </div>
    </section>
  );

};

Gallery.propTypes = {
  title: PropTypes.string,
  mainSlides: PropTypes.arrayOf(PropTypes.object).isRequired,
  thumbSlides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery;
