import React from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import "./ToggleLang.scss";

const ToggleLang = ({className, arText = "AR", enText="EN", dir, oneString}) => {

  return (
    <LanguageContext.Consumer>
      {(context) => {

        context.checkLang();

        return (
          <ul className={`toggle-lang ${context.lang === "ar" ? "active" : ""} ${className || ""} ${dir} ${oneString ? 'toggle-lang--one-string' : ''}`}>
            <li onClick={() => context.toggleLang(`${oneString ? "en" : "ar"}`)} className={oneString ? context.lang === "ar" ? "active" : "" : context.lang === "en" ? "active" : ""}>{enText}</li>
            <li onClick={() => context.toggleLang(`${oneString ? "ar" : "en"}`)} className={oneString ? context.lang === "en" ? "active" : "" : context.lang === "ar" ? "active" : ""}>{arText}</li>
          </ul>
        );

      }}
    </LanguageContext.Consumer>
  )

};

ToggleLang.propTypes = {};

export default ToggleLang;
