import axios from "axios";

const API = (url, data, callback, error, method = "POST", headers = {}) => {
  url = "" + url;


  const formSuccess = response => {
    if (response && (response.status === 200 || response.status === 201) ) {
      const data = response.data;
      if (data && data.length > 0){
        return error ? error(data[0]) : [];
      }

      return callback ? callback(data) : data;
    } else {
      return error ? error(data) : [];
    }
  };

  const formError = err => error ? error(err) : [];

  let bodyFormData = new FormData();

  for (let key in data) {
    bodyFormData.append(key, data[key]);
  }

  const request = method === "GET" ? axios.get(url, headers) : axios.post(url, bodyFormData, headers);
  request.then(response => formSuccess(response)).catch(err => formError(err));

};

export default API;
