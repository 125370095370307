import React, { useContext } from 'react';
import Image from "../../components/UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";
import { dillers, phones } from "../../content/regions.json";
import footer_data from "../../content/footer.json";

import "./Another-footer.scss";
import Social from "../../components/Social";

const AnotherFooter = ({ region, dir }) => {
    const { lang, getTranslate } = useContext(LanguageContext);
    const content = footer_data[lang][region] ? footer_data[lang][region] : null;

    return (
        <footer className={`pp-section pp-section-footer ${region}`} dir={dir}>
            <div className={"container"}>
                <div className={"logos"}>
                    <div className="pp-section-footer__dealers-content">
                        <Social region={region} lang={lang} dir={dir} />
                        { content?.tel &&
                            <a className="pp-section-footer__tel pp-section-footer__tel--inline" href={`tel:${content.tel}`} aria-label='click to call'>{content.tel}</a>
                        }
                        { dillers[region] && (<div className={"dealer"}>
                            {content?.dillers_link ?
                                <a className="pp-section-footer__diller" href={content.dillers_link} aria-label='diller link' target="_blank"  rel="noreferrer noopener">
                                    <Image
                                    useLazy={true}
                                    className={`rivenditore ${region.toLowerCase()}`}
                                    src={getTranslate(dillers[region])}
                                    alt={"dealer"}/>
                                </a>
                             : <Image
                                useLazy={true}
                                className={`rivenditore ${region.toLowerCase()}`}
                                src={getTranslate(dillers[region])}
                                alt={"dealer"}
                            />}
                        </div>)}
                        {phones[region] && (
                            phones[region].telNumber
                            ? (
                                <a className="pp-section-footer__tel" href={`tel:${phones[region].telNumber}`} aria-label='click to call'>
                                    <Image
                                        useLazy={true}
                                        className={`pp-section-footer__tel-img`}
                                        src={phones[region].icon[lang] || phones[region].icon}
                                        alt={"phones"}
                                    />
                                </a>
                            )
                            : (
                                <div className="pp-section-footer__tel">
                                    <Image
                                        useLazy={true}
                                        className={`pp-section-footer__tel-img`}
                                        src={phones[region].icon[lang] || phones[region].icon}
                                        alt={"phones"}
                                    />
                                </div>
                            )

                        )}
                    </div>
                    {content?.logo_link ?
                        <a className="pp-section-footer__logo logo" href={content.logo_link} aria-label='logo link' target="_blank"  rel="noreferrer noopener">
                            <Image className={"logo"} src={"svg/logo-w.svg"} useLazy={true} alt={"Hyundai"}/>
                        </a>
                        : <Image className={"logo"} src={"svg/logo-w.svg"} useLazy={true} alt={"Hyundai"}/>}

                </div>
                <p className={"text"}>{getTranslate('footer.copyright')}</p>
            </div>
        </footer>
    );
};

export default AnotherFooter;
