import React from 'react';
import PropTypes from 'prop-types';
import {LanguageContext} from "../../../contexts/LanguageContext";
import {Field, useFormikContext} from "formik";

const Select = ({field, input, placeholder, beforeChange, errors, touched}) => {

    const {values, setFieldValue} = useFormikContext();

    React.useEffect(() => {
        if (input.child && values[field]) {
            beforeChange(input.child, field, values[field], input.source)
        }
        if (arrayContainsObjectValue(input.options, values[field])) {
            setFieldValue(field, values[field]);
        } else {
            setFieldValue(field, "");
        }


    }, [input, values, setFieldValue, input.options])

    const arrayContainsObjectValue = (array, value) => {
        let output = false;
        array.forEach(element => {
            if (element.hasOwnProperty("value") && element.value === value)  {output = true;};
        })
        return output;
    }

    const onChange = (event) => {
        setFieldValue(field, event.currentTarget.value)
    }


    return (
        <LanguageContext.Consumer>
            {context => {

                return (
                    <div className={`input select ${errors[field] && touched[field] ? "has-error" : ""}`}>
                        <Field as={"select"}
                               id={field}
                               name={field}
                               value={values[field]}
                               onChange={(event) => {
                                   onChange(event)
                               }}
                               placeholder={context.getTranslate(input.placeholder)}>
                            <option selected={true} disabled
                                    value={""}>{context.getTranslate(input.placeholder)}</option>
                            {input.options.map((option, i) => {
                                return <option key={`option-${i}`}
                                    value={context.getTranslate(option.value)}>{context.getTranslate(option.label)}</option>
                            })}
                        </Field>
                    </div>
                );

            }}
        </LanguageContext.Consumer>
    )

};

Select.defaultProps = {
    placeholder: ""
};

Select.propTypes = {
    field: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
};

export default Select;
