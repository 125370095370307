import React from 'react';
import PropTypes from 'prop-types';
import Image from "../../../UI/Images/Image";
import TextBlock from "../../../UI/TextBlock/TextBlock";
import { LanguageContext } from "../../../../contexts/LanguageContext";

import "./defaultSlide.scss";

const DefaultSlide = ({ className, slideInfo, index, length, counter }) => {

  return (
    <LanguageContext.Consumer>
      {context => {
        return (
          <div className={`default-slide swiper-slide ${className} ${slideInfo.className || ''}`} style={{ ...slideInfo.style }}>
            <div className={"wrapper"}>
              <Image src={slideInfo.image} useLazy={false} placeholder={"placeholders/2240.jpg"}/>
              <div className={"info-block"}>
                {counter && <div className={"counter"}>{index + 1} {context.getTranslate("other.of")} {length}</div>}
                {slideInfo.title || slideInfo.subtitle  ? <TextBlock title={slideInfo.title}
                           subtitle={slideInfo.subtitle}
                           isMain={true} /> : ""}
              </div>
            </div>
          </div>
        )
      }}
    </LanguageContext.Consumer>
  )
};

DefaultSlide.defaultProps = {
  className: "",
  index: 0,
  length: 0,
};

DefaultSlide.propTypes = {
  slideInfo: PropTypes.object.isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  length: PropTypes.number,
};

export default DefaultSlide;
