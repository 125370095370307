import React, { useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import "./CardImg.scss"
import Image from "../../components/UI/Images/Image";
import Parser from "html-react-parser";
import { LanguageContext } from "../../contexts/LanguageContext";

import Slider from "../../components/Slider/Slider";


export const CardImg = ({ title, subtitle, description, src, isSmall, isReverse, dir, isReversed, slides}) => {
  return (
    <LanguageContext.Consumer>

        {context => {

            return (
                <div className={[`pp-section card-img ${dir}`, isReversed ? 'card-img--reversed' : ''].join(' ')}>

                    <div className={"card-img__content"}>
                        <div className={['container info-wrapper', isReverse ? 'info-wrapper--reversed' : ''].join(' ')}>
                            <div className={"info-wrapper__body"}>
                                {title &&<div className={"title"}>{Parser(context.getTranslate(title))}</div>}
                                {subtitle &&<div className={"subtitle"}>{Parser(context.getTranslate(subtitle))}</div>}
                                <div className={"description"}>{Parser(context.getTranslate(description))}</div>
                            </div>
                            {isSmall &&
                                <div className={"img-wrapper--small"}>
                                    {slides && (
                                        <Slider slides={slides}
                                            dir={dir}
                                            config={{
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                            loop: true,
                                            useControls: true,
                                            usePagination: true,
                                            autoplay: {
                                                delay: 3000,
                                            },
                                            effect: 'fade',
                                            }}
                                        />
                                    )}
                                     <img src={src}/>
                                </div>
                            }
                        </div>
                    </div>
                    {!isSmall &&
                        <div className={"img-wrapper"}>
                            {slides && (
                                <Slider slides={slides}
                                    dir={dir}
                                    config={{
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                    loop: true,
                                    useControls: true,
                                    usePagination: true,
                                    autoplay: {
                                        delay: 3000,
                                      },
                                    effect: 'fade',
                                    }}
                                />
                            )}
                            <img src={src}/>
                        </div>
                    }
                </div>
            )
        }}
        </LanguageContext.Consumer>
  )

};

CardImg.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};
