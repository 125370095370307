import React from 'react';
import './Offer.scss'
import Image from "../../components/UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";
import Parser from "html-react-parser";

export const Offer = ({title, terms, dir}) => {
  return (
    <LanguageContext.Consumer>

      {context => {

        return (

          <div className={`pp-section terms ${dir}`}>
            <div className="container">
              <div className={"title"}>{Parser(context.getTranslate(title))}</div>
              <ul>
                {terms.map((term, i) => <li key={`term-${i}`}>
                  <img src={process.env.PUBLIC_URL+"/assets/images/icons/terms.png"}/>
                  {Parser(context.getTranslate(term))}
                </li>)}
              </ul>
            </div>
          </div>)
      }}
    </LanguageContext.Consumer>
      )};
