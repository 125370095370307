import React, {Suspense, useState} from 'react';
import ReactGA from 'react-ga';
import {YMInitializer} from 'react-yandex-metrika';
import TagManager from 'react-gtm-module';
import LazyLoad from 'react-lazyload';
import {LanguageContext} from "../../contexts/LanguageContext";

import Header from "../../layouts/Header/Header";
import Hero from "../../layouts/Hero/Hero";
// import Footer from "../../layouts/Footer/Footer";
import AnotherFooter from '../../layouts/another-footer/Another-footer';
// import { links } from "../../content/regions.json";
import combinationJSON from "../../content/combination.json";
// import tabsJSON from "../../content/tabs.json";
import sliderJSON from "../../content/slider.json";
import sliderCardsJSON from "../../content/sliderCards.json";
import sliderImgJSON from "../../content/sliderImg.json";
import learnMoreJSON from "../../content/learnMore.json";
import MoveCarJSON from "../../content/moveCar.json";
import heroJSON from '../../content/hero.json';
// import casesJSON from "../../content/cases.json";
// import galleryJSON from "../../content/gallery.json";
import {buttons, fields, locations} from "../../content/regions.json";
import Sprite from "../../components/UI/SvgSprite/Sprite";
// import {Models} from "../../layouts/Models/Models";
// import {Tabs} from "../../layouts/Tabs/Tabs";
import PopupYoutube from "../../components/Popups/PopupYoutube";
// import {Play} from "../../layouts/Play/Play";
import {MoveCar} from "../../layouts/MoveCar/MoveCar";
import {CardImg} from "../../layouts/CardImg/CardImg";
import FormBasic from "../../layouts/FormBasic/FormBasic";
import Image from "../../components/UI/Images/Image";
// import TextBlock from "../../components/UI/TextBlock/TextBlock";
// import Gallery from "../../layouts/Gallery/Gallery";
import SliderCard from "../../layouts/SliderCard/SliderCard";
import SliderWideCard from "../../layouts/SliderWideCard/SliderWideCard";
import SliderImg from '../../layouts/SliderImg/SliderImg'
import {Offer} from "../../layouts/Offer/Offer";
import {More} from "../../components/More/More";
import LearnMore from "../../layouts/LearnMore/LearnMore"
import banksJSON from '../../content/banks.json';
import Gallery from '../../layouts/Gallery/Gallery';

const Main = ({match}) => {

  const modelsArray = [
    {
      id: 1,
      name: "GDi",
      text: "GDi"
    },
    {
      id: 2,
      name: "Hybrid",
      text: "Hybrid"
    },
    {
      id: 3,
      name: "NLine",
      text: "N-Line"
    }
  ]

  const scrollRef = React.useRef();
  const [link, setLink] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(modelsArray[0]);

  const tagManagerArgs = {
    gtmId: 'GTM-MXSCVWW'
  };

  const region = match.params.region;
  const car = selectedIndex.name;

  let lang = LanguageContext._currentValue;

  const [bank, setBank] = useState(banksJSON[region] ? banksJSON[region][0].name : '');

  function onBankChange(val) {
    if (!banksJSON[region]) return;
    const index = banksJSON[region].findIndex(i => i.name === val);
    setBank(banksJSON[region][index]?.name)
  }

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);

    const country = region === "Egypt" ? "Egypt" : "Saudi Arabia";
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<img src="https://ad.doubleclick.net/ddm/activity/src=3220295;type=gquo;cat=hyund0;u1=${window.location.href};u2=${lang};u3=${country};u4=${region};u5=Elantra;u6=none;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>`;
    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'u1': window.location.href,
      'u2': LanguageContext._currentValue,
      'u3': country,
      'u4': region,
      'u5': 'Sonata',
      'u6': 'none',
      'send_to': 'DC-3220295/gquo/hyund0+unique'
    });
    document.querySelector("body").appendChild(noscript);
  });

  // const formFields = fields?.[region] || {
  //   "firstName": {
  //     value: "",
  //     placeholder: "inputs.firstName"
  //   },
  //   "lastName": {
  //     value: "",
  //     placeholder: "inputs.lastName",
  //   },
  //   "email": {
  //     value: "",
  //     placeholder: "inputs.email",
  //     type: "email",
  //   },
  //   "phone": {
  //     value: "",
  //     placeholder: "inputs.mobile",
  //     type: "tel",
  //   }
  // };

  const formFields = fields?.[region] || fields.default;

  if (locations?.[region]) {
    !!formFields["city"] ? Object.assign(formFields["city"], {options: locations[region]}) : formFields["city"] = {
      value: "",
      type: "select",
      placeholder: "inputs.location",
      defaultValue: "location",
      options: locations[region]
    };
  }

  const terms = [
    "terms.term_1",
    "terms.term_2",
    "terms.term_3",
    "terms.term_4",
    "terms.term_5",
    "terms.term_6",
  ]

  return (
    <LanguageContext.Consumer>

      {context => {

        context.checkLang();
        lang = context.lang;
        lang = lang === "ar" ? "" : "en";

        const dir = context.getTranslate("direction");
        return (
          <main className={`pp-page-sonata ${dir}`}
                dir={dir}>
            <YMInitializer options={{
              webvisor: true, clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true
            }} accounts={[69203749]}/>
            <PopupYoutube
              btnOpen={"js-open-youtube"}
              youtubeId={context.getTranslate("video.play")}/>
            <Sprite/>
            <Header
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    region={region}
                    lang={lang}
                    modelsArray={modelsArray}
                    dir={dir}/>
            <Hero
                  title={heroJSON.main.title}
                  subtitle={heroJSON.main.subtitle}
                  region={region}
                  useYoutube={true}
                  video={true}
                  dir={dir}/>
            {region === 'Jeddah' &&
              <Offer dir={dir}
                      title={"terms.title"}
                      terms={terms}/>
            }

            {/* <FormBasic fields={formFields}
                       lang={lang}
                       region={region}
                       button={buttons?.[region] || ""}
                       dir={dir}/> */}

                <FormBasic
                    fields={formFields}
                    onBankChange={onBankChange}
                    bank={bank}
                    lang={lang}
                    region={region}
                    button={buttons?.[region] || ""}
                    // setSelectedCar={setSelectedCar}
                    dir={dir}
                    grid={true}
                />

            <MoveCar
                  // src={src}
                  src={MoveCarJSON.slides?.[region]?.[car].src}
                  title={"main_play.title"}
                  subtitle={"main_play.subtitle"}
                  dir={dir}
                  modelsArray={modelsArray}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
            />

            {sliderCardsJSON.slides &&
            <LazyLoad height={200}><Suspense fallback={<div></div>}>
              <CardImg  title={sliderCardsJSON.slides?.[region]?.banner?.[car]?.title}
                        description={sliderCardsJSON.slides?.[region]?.banner?.[car]?.description}
                        src={sliderCardsJSON.slides?.[region]?.banner?.[car]?.img}
                        dir={dir}
                        // slides={sliderImgJSON.slides[region]?.[car]}
                        isLazyLoadImg
                        />
                        </Suspense></LazyLoad>
            }

            {/* {sliderImgJSON.slides &&
              <LazyLoad height={200}><Suspense fallback={<div></div>}>
                <SliderImg
                    className={"container"}
                    slides={sliderImgJSON.slides[region]?.[car]}
                    dir={dir}
                    isLazyLoadImg
                    usePagination={true}
                    slidesPerView={1}
                /></Suspense></LazyLoad>
              } */}
            {/* <Tabs dir={dir}
                    id={"tabs"}
                    title={tabsJSON.slides.title}
                    subtitle={tabsJSON.slides.subtitle}
                    images={tabsJSON.slides[region]}
                    /> */}


            {sliderCardsJSON.slides &&
              <LazyLoad height={200}><Suspense fallback={<div></div>}>
                <SliderWideCard
                    slides={sliderCardsJSON.slides[region]?.[car]}
                    dir={dir}
                    isLazyLoadImg
                    usePagination={true}
                    slidesPerView={3}
                /></Suspense></LazyLoad>
              }
          {combinationJSON.slides &&
          <LazyLoad height={200}><Suspense fallback={<div></div>}>
            <SliderCard
                title={combinationJSON.slides[region]?.titles?.[car]}
                // subtitle={combinationJSON.slides.subtitle}
                slides={combinationJSON.slides[region]?.[car]}
                dir={dir}
                isLazyLoadImg
                usePagination={true}
                slidesPerView={3}
                className='pp-section-slider-cards--combination'
                counter={true}
            /></Suspense></LazyLoad>
          }

          {sliderJSON.slides &&
            <LazyLoad height={200}><Suspense fallback={<div></div>}>
              <SliderCard
                  title={sliderJSON.slides.title}
                  // subtitle={sliderJSON.slides.subtitle}
                  slides={sliderJSON.slides[region]?.[car]}
                  dir={dir}
                  isLazyLoadImg
                  usePagination={true}
                  slidesPerView={3}
                  className='pp-section-slider-cards--smart-sense'
              /></Suspense></LazyLoad>
          }

            {/* {sliderImgJSON.slides &&
              <LazyLoad height={200}><Suspense fallback={<div></div>}>
              <CardImg  subtitle={"card_sport.subtitle"}
                      description={"card_sport.description"}
                      src={"/assets/images/cards/4.jpg"}
                      dir={dir}
                      isReversed={true}
                      slides={sliderImgJSON.slides[region]?.[car]}
                      /></Suspense></LazyLoad>
            } */}

            <Hero images={"/desktop/hero/hero.jpg"}
                  useYoutube={false}
                  className={"hero-image"}
            />

            {/* <TextBlock subtitle={"terms.text"}
                      className={"container"}/> */}

            {/* {sliderImgJSON.slides &&
              <LazyLoad height={200}><Suspense fallback={<div></div>}>
                <CardImg  subtitle={"card_power.subtitle"}
                      description={"card_power.description"}
                      src={"/assets/images/cards/6.jpg"}
                      isSmall={true}
                      isReverse={true}
                      dir={dir}
                      slides={sliderImgJSON.slides[region]?.[car]}
                      /></Suspense></LazyLoad>
            } */}
            <LazyLoad height={200}>
              <Suspense fallback={<div></div>}>
                <LearnMore
                  // title={learnMoreJSON.title[region]}
                  subtitle={learnMoreJSON.subtitle[region]}
                  // link={links[region]}
                  region={region}
                  btns={learnMoreJSON.btns}
                  car={car}
                />
              </Suspense>
            </LazyLoad>

            {/* {sliderImgJSON.slides &&
              <LazyLoad height={200}><Suspense fallback={<div></div>}>
                <SliderImg
                    slides={sliderImgJSON.slides[region]?.common}
                    dir={dir}
                    isLazyLoadImg
                    usePagination={true}
                    slidesPerView={1}
                /></Suspense></LazyLoad>
              } */}

              {sliderImgJSON.slides &&
                // <LazyLoad height={200}><Suspense fallback={<div></div>}>
                //   <SliderImg
                //       slides={sliderImgJSON.slides[region]?.common}
                //       dir={dir}
                //       isLazyLoadImg
                //       usePagination={true}
                //       slidesPerView={1}
                //       counter={true}
                //   /></Suspense></LazyLoad>

                  <LazyLoad height={200}><Suspense fallback={<div></div>}><Gallery mainSlides={sliderImgJSON.slides[region]?.common}
                  thumbSlides={sliderImgJSON.slides[region]?.common}
                  dir={dir}
                  title={sliderImgJSON.slides.title}
                  counterClass={'black-counter'}
                  /></Suspense></LazyLoad>
                }

                {region === 'Jeddah' &&
                  <More />
                }

            <AnotherFooter dir={dir} diller={true} region={region} lang={lang}/>

            <Image src={"/icons/top.svg"} className={"top-button mobile"} onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              })
            }}/>
          </main>
        )
      }}
    </LanguageContext.Consumer>
  );

};

Main.propTypes = {};

export default Main;
