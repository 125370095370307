import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LanguageContext } from "../../../contexts/LanguageContext";
import { Field } from "formik";

const Input = ({ field, input, errors, touched, setFieldValue }) => {
  const {lang} = useContext(LanguageContext);

  const onChange = (e) => {
    setFieldValue(field, e.currentTarget.value);
    if (input?.errorMessage) {
      input.errorMessage = undefined;
    }
  }

  return (
    <LanguageContext.Consumer>
      {context => {

        return (
          <div className={`input ${(input.errorMessage || errors[field]) && touched[field] ? "has-error" : ""}`}>
            <Field
              type={input?.type || "text"}
              id={field}
              name={field}
              placeholder={context.getTranslate(input.placeholder)}
              onChange={onChange}
            />
            {input.errorMessage && <span className={`error`}>{input.errorMessage?.[lang] || input.errorMessage}</span>}
          </div>
        );

      }}
    </LanguageContext.Consumer>
  )

};

Input.defaultProps = {
  placeholder: ""
};

Input.propTypes = {
  field: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export default Input;
