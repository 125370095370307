import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import "./Hero.scss";
import useGetCurrentOrientation from '../../hooks/useGetCurrentOrientation';
import { heroVideos } from "../../content/heroVideo.json";

const Hero = ({ dir, useYoutube, images, title, subtitle, region, black ,className}) => {

  const videoRef =useRef();

  const currentOrientation = useGetCurrentOrientation();
  const currentVideo = heroVideos[region]?.[currentOrientation] || heroVideos.default[currentOrientation];

  useEffect(() => {
    if (!videoRef.current) return;

      videoRef.current.load();
  }, [currentOrientation]);
  return (
    <section className={`pp-section-hero ${black ? "black" : "white"} ${dir} ${className || ''}`} dir={dir}>
      {images && <Image alt={title} src={images} useLazy={false}/>}
      { currentVideo &&
      <div className={"wrapper_video"}>
        <video  ref={videoRef}
                className={"video js-hero-video"}
                playsInline
                muted="muted"
                loop
                pip="false"
                autoPlay
                poster={process.env.PUBLIC_URL + "/assets/images/desktop/hero/poster.jpg"}>
          {/* <source type="video/mp4" src={process.env.PUBLIC_URL + "/assets/bg.mp4"} /> */}
          {currentVideo.src.map((video, n) => (
                                <source key={'video-source-' + n} src={video.src} type={video.type} />
                            ))}
        </video>
      </div>
      }

      <div className={"container"}>
        <TitleBlock isMain={true}
                    // className={"white"}
                    title={subtitle}
                    subtitle={title}
                    useYoutube={useYoutube} />
      </div>
    </section>
  );

};

Hero.propTypes = {
  region: PropTypes.string,
  dir: PropTypes.string,
};

export default Hero;
