import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import Main from "./pages/Main/Main";
import Regions from "./pages/Regions/Regions";
import ThankYou from "./pages/ThankYou/ThankYou";

export const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={`${process.env.REACT_APP_URL}:region/:lang?/thank_you`} component={ThankYou} />
          <Route exact path={`${process.env.REACT_APP_URL}:region/:lang?`} component={Main} />
          <Route exact path={`${process.env.REACT_APP_URL}/`} component={Regions} />
        </Switch>
      </Router>
    </div>
  );
};
