import React from 'react';
import {LanguageContext} from "../../contexts/LanguageContext";
import Parser from "html-react-parser";
import "./descriptionBlock.scss";


class DescriptionBlock extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            animClass: "anim",
            busy: false,
            list: props.list
        }
    }

    componentDidMount() {
        if (this.props.useAnim) this.sequentalAnimation("anim", "reverse-anim");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.list !== this.props.list) {
            if (this.props.useAnim) {
                this.sequentalAnimation("reverse-anim", "anim", 10);
                setTimeout(() => {
                    this.sequentalAnimation("anim", "reverse-anim", 100)
                }, 400);
                setTimeout(() => {
                    this.setState({list: this.props.list, busy: false})
                }, 410);

            } else {
                this.setState({list: this.props.list});
            }
        }
        if (prevProps.dir !== this.props.dir){
            this.sequentalAnimation("anim", "reverse-anim", 100);
        }
    }

    sequentalAnimation(className, className2, timeout) {

        if (!this.state.busy) {
            this.setState({busy: true});
            this.ref.current.querySelectorAll(".item").forEach((item, index) => {
                setTimeout(() => {
                    item.classList.remove(className2);
                    item.classList.add(className);
                }, index * timeout);
                this.setState({busy: false});
            })
        }
    }


    render() {
        const {svgColor, className, dir} = this.props;
        const {list} = this.state;
        return (
            <LanguageContext.Consumer>

                {context => {
                    return (
                        <div ref={this.ref} className={`description-block ${className || ""} `} dir={dir}>
                            {list.map((item, index) => {
                                return <div key={index} className={`item ${dir}`} dir={dir}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="11.5" stroke={svgColor}/>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M17.3588 9.34824L11.1732 15.7213L7.63806 12.0116L8.362 11.3217L11.1798 14.2787L16.6412 8.65176L17.3588 9.34824Z"
                                              fill={svgColor}/>
                                    </svg>
                                    {Array.isArray(item) ?
                                        <ul>{item.map(line => <li>{Parser(context.getTranslate(line))}</li>)}</ul> :
                                        <p>{Parser(context.getTranslate(item))}</p>
                                    }
                                </div>
                            })}
                        </div>
                    )
                }}
            </LanguageContext.Consumer>
        );
    }
}

export default DescriptionBlock;