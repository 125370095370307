import React from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
// import "yup-phone";

import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import Select from "../../components/UI/Select/Select";
import { cityByCodes } from '../../content/regions.json';
import { en } from "../../content/translate.json";

import "./FormBasic.scss";
import API from "../../Helpers/API";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import axios from 'axios';

const FormBasic = ({
  fields,
  region,
  dir,
  button,
  lang,
  bank,
  setSelectedCar,
  onBankChange,
  grid
}) => {
  const [isBusy, setBusy] = React.useState(false);
  const schemaFields = {};

  Object.keys(fields).map(field => {
    switch (field) {
      case ("firstName"):
        schemaFields[field] = Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required');
        break;
      case ("lastName"):
        schemaFields[field] = Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required');
        break;
      case ("email"):
        schemaFields[field] = Yup.string()
          .email('Invalid email')
          .required('Required');
        break;
      case ("phone"):
        if (region === "Qatar") {
          schemaFields[field] = Yup.string()
          .length(13)
          .required('Required');
        } else if (region === "Jeddah") {
          schemaFields[field] = Yup.string()
          .min(region === "Jeddah" ? 10 : 1, 'Too Short!')
          .max(region === "Jeddah" ? 10 : 100, 'Too Long!')
          .required('Required');
        } else {
          schemaFields[field] = Yup.string()
          .min(1, 'Too Short!')
          .max(100, 'Too Long!')
          .required('Required');
        }
        break;
      default:
        schemaFields[field] = Yup.string()
          .min(1, 'Too Short!')
          .required('Required')
    }
  });

  const validationSchema = Yup.object().shape(schemaFields);

  const collectMetric = data => {
    const metrics = ["campaign", "sourcee"];
    const baseURL = window.location.search.split("?")?.[1];
    const splitURL = baseURL ? baseURL.split("&") : [];

    splitURL.forEach(url => {
      metrics.forEach(metric => {
        const splitMetric = url.split(`${metric}=`);

        if (splitMetric?.[1]) {
          data[metric] = splitMetric[1]
        }

      })
    });

    return data;
  };

  const collectUTM = data => {
    const UTMS = ["utm_source", "utm_medium", "utm_content", "utm_campaign", "utm_term"];
    const splitURL = window.location.search.split("&");

    if (splitURL[1]) {
      splitURL.forEach(url => {

        UTMS.forEach(utm => {
          const splitUTM = url.split(`${utm}=`);

          if (splitUTM[1]) {
            const utmSplit = utm.split("_");
            utm = utmSplit[0] + utmSplit[1].charAt(0).toUpperCase() + utmSplit[1].slice(1);
            data[utm] = splitUTM[1];
          }
        });
      });
    }
    return data;
  };

  const onSubmit = (values, { setSubmitting, setErrors, setFieldError, setStatus, resetForm }) => {
    TagManager.initialize('DC-3220295');

    ReactGA.pageview(region);
    if (isBusy) {
      return false;
    }
    setBusy(true);
    values.name = values.city;
    values.region = region.toLowerCase();
    const name = values.model;

    values.vehicle = values.model ? values.model.replace(`${name.toLowerCase()}`, `${name}`) : "Sonata";
    if (region === 'morocco') values.vehicle = "i20";
    values["Source"] = "Microsite";
    values["utmSource"] = "Microsite";

    values = collectUTM(values);
    values = collectMetric(values);

    if (values.phone) {
      values.phone = values.phone.replace(" ", "");
    }

    const cityCode = !isNaN(Number(values.city)) ? values.city : undefined;

    if (!isNaN(Number(values.city))) {
      values.name = en.locations?.[cityByCodes[values.city]];
    }

    let url = process.env.REACT_APP_ADMIN_URL;
    if (region === "Jeddah") {
      url += "jeddah/"
    } else {
      url += "contact"
    }

    API(url, values, () => {
      setBusy(false);
      if (window.dataLayer) {
        window.dataLayer.push({'event': "Successful_FormSubmit"})
      }
      const bank = values.bank ? values.bank : "none";
      resetForm({});
      setStatus({ success: true });
      const utm = window.location.search ? window.location.search + `&bank=${bank}` : `?bank=${bank}`;
      const path = window.location.origin + window.location.pathname;
      const ending = path.slice(path.length - 1, path.length);
      const { firstName, lastName, email, phone, city } = values;

      if (region === 'morocco') {
        axios.post("https://connect.hyundai.ma/leads.ws.php", {
            fname: firstName,
            lname: lastName,
            email,
            tel: phone,
            city: cityCode,
            region: region,
            model:"i20",
            source:"2022 Ramadan",
        })
          .then(() => {
            window.location.href = path + `${ending === "/" ? "" : "/"}thank_you` + utm;
          })
          .catch(err => console.error(err));
      } else {
        window.location.href = path + `${ending === "/" ? "" : "/"}thank_you` + utm;
      }
    }, ({ response }) => {
      const errors = response?.data?.errors || {};

      if (Object.keys(errors).length > 0) {
        for (let key in values) {
          if (errors[key] && (Object.keys(errors?.[key]).length || typeof errors?.[key] === "string")) {
            fields[key]["errorMessage"] = errors[key];
          }
        }
      }

      setStatus({ success: false });
      setBusy(false);
    }, "POST", { "Content-Type": "application/json" });
  };

  const defaultValues = {};

  Object.keys(fields).forEach(field => {
    defaultValues[field] = fields[field].value;
  });

  const setOptions = (target, parent, value, source) => {
    let array = [];
    fields[parent]["options"].forEach(option => {
      if (option.value === `${value.replace(" ", "_")}`) {
        array = option[source]
      }
    });
    fields[target].options = array;
    fields[target].value = '';
  }

  const onParentInputChange = (target, field, value, source) => {
    setOptions(target, field, value, source);
  }

  const classForm = () =>{
    if(region === 'Palestine'){
      return 'input-palestine'
    }
    return ''
  }

  return (
    <section id={"form-section"} className={`pp-section-form ${region}`}>

      <div className={`container ${classForm()}`}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={defaultValues}
          onSubmit={onSubmit}>
          {
            ({ errors, touched, setFieldValue }) => (
              <Form dir={dir}>
                <div className={`input-wrapper ${grid ? 'input-wrapper--grid' : ''}`}>{
                  Object.keys(fields).map(field => {
                    const input = fields[field];

                    if (input.type === "select") {
                      if (field === 'bank') {
                        return <Select
                          key={input.placeholder}
                          field={field}
                          beforeChange={onParentInputChange}
                          input={input}
                          errors={errors}
                          setSelectedCar={setSelectedCar}
                          touched={touched}
                          bank={bank}
                          onBankChange={onBankChange}
                        />
                      } else {
                        return <Select
                          key={input.placeholder}
                          field={field}
                          beforeChange={onParentInputChange}
                          input={input}
                          errors={errors}
                          setSelectedCar={setSelectedCar}
                          touched={touched}
                        />
                      }
                    } else {
                      return <Input
                        key={input.placeholder}
                        field={field}
                        input={input}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    }
                  })
                }
                  <Button text={button || `btns.request`} className={"white small"} />
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </section>
  );

};

FormBasic.propTypes = {
  fields: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
};

export default FormBasic;
