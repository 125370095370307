import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Button from "../../components/UI/Button/Button";
import {LanguageContext} from "../../contexts/LanguageContext";

import "./styles.scss";

const LearnMore = ({title, subtitle, btns, link, region, onFileDownload, car}) => {
    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <section className={"pp-section pp-section-learn-more"}>
                        <div className={"container pp-section-learn-more__container"}>
                            <div className={"btns pp-section-learn-more__btns"}>
                                {btns.map(btn => {
                                    return (
                                        <Button
                                            key={btn.label[region]}
                                            text={btn.label[region]}
                                            className={btn.className}
                                            isFile={btn.isFile}
                                            onClick={btn.isFile ? btn.onFileDownload : btn.isScrollForm ? () => {
                                                document.getElementById('form-section').scrollIntoView({block: "center", behavior: "smooth"});
                                            } : ()=>{}}
                                            link={btn.isFile ? btn.link : btn.link?.[region]?.[car]}
                                            download={btn.onFileDownload}
                                        />)}
                                    )
                                }
                            </div>
                            <TitleBlock title={title}
                                        subtitle={subtitle}
                                        className={"center"}/>
                        </div>
                    </section>
                );
            }}
        </LanguageContext.Consumer>
    )

};

LearnMore.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  btns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LearnMore;
