import React, { useEffect,useRef, useState } from 'react';
import PropTypes from 'prop-types';
import "./MoveCar.scss"
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import { Bubble } from "../../components/UI/Bubble/Bubble";
import Image from "../../components/UI/Images/Image";

import {gsap, ScrollTrigger} from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);

export const MoveCar = ({ title, subtitle, bubble, src, dir, modelsArray, setSelectedIndex, selectedIndex }) => {
    useEffect(() => {
        if ( dir === "rtl") {
          ScrollTrigger.matchMedia({
            "(min-width: 1400px)": () => {
                gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.image-wrapper',
                    },
                }).fromTo('.image-wrapper img', {
                    x: 2500,
                    y: -100,
                }, {
                    x: 100,
                    y: -100,
                    duration: 1,
                    delay: .5
                }, {});
            },
            "(max-width: 1399px)": () => {
              gsap.timeline({
                  paused: true,
                  scrollTrigger: {
                      trigger: '.image-wrapper',
                  },
              }).fromTo('.image-wrapper img', {
                  x: 2500,
                  y: -200,
              }, {
                  x: 530,
                  y: -200,
                  duration: 1,
                  delay: .5
              }, {});
            },
            "(max-width: 1023px)": () => {
                gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.image-wrapper',
                    },
                }).fromTo('.image-wrapper img', {
                    x: 2500,
                    y: -159,
                }, {
                    x: 405,
                    y: -159,
                    duration: 1,
                    delay: .5
                }, {});
            },
            "(max-width: 767px)": () => {
                gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.image-wrapper',
                    },
                }).fromTo('.image-wrapper img', {
                    x: 2500,
                    y: -60,
                }, {
                    x: 410,
                    y: -60,
                    duration: 1,
                    delay: .5
                }, {});
            },
        });
        } else {
            ScrollTrigger.matchMedia({
                "(min-width: 1400px)": () => {
                    gsap.timeline({
                        paused: true,
                        scrollTrigger: {
                            trigger: '.image-wrapper',
                        },
                    }).fromTo('.image-wrapper img', {
                        x: 1500,
                        y: -100,
                    }, {
                        x: -90,
                        y: -100,
                        duration: 1,
                        delay: .5
                    }, {});
                },
                "(max-width: 1399px)": () => {
                gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.image-wrapper',
                    },
                }).fromTo('.image-wrapper img', {
                    x: 2500,
                    y: -200,
                }, {
                    x: 0,
                    y: -200,
                    duration: 1,
                    delay: .5
                }, {});
                },
                "(max-width: 1023px)": () => {
                gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.image-wrapper',
                    },
                }).fromTo('.image-wrapper img', {
                    x: 2500,
                    y: -159,
                }, {
                    x: 0,
                    y: -159,
                    duration: 1,
                    delay: .5
                }, {});
                },
                "(max-width: 767px)": () => {
                gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.image-wrapper',
                    },
                }).fromTo('.image-wrapper img', {
                    x: 1500,
                    y: -60,
                }, {
                    x: -120,
                    y: -60,
                    duration: 1,
                    delay: .5
                }, {});
            },
          });
        }
      }, [src]);

    const handleButtonClick = (index) => {
        const id = index + 1;
        setSelectedIndex(modelsArray.find((item) => id === item.id));
    }
  return (
    <div className={`pp-section move-car ${dir}`}>
      <div className={"container"}>
        <TitleBlock title={title} subtitle={subtitle}/>

        <div className={"image-wrapper"}>
            <Image src={src} className={"sonata-move"}/>

        </div>
        {Array.isArray(modelsArray) && (
            <div className='move-car__buttons'>
                {modelsArray.map((button, index) => {
                    return (
                        <button className={`move-car__button ${(selectedIndex.id - 1) === index ? 'move-car__button--active' : ''}`} type='button' key={`move-car-button-${index}`} onClick={() => handleButtonClick(index)}>
                            {button.text}
                        </button>
                    );
                })}
            </div>
        )}
      </div>
    </div>
  )

};

MoveCar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};