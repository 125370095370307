import { useCallback, useEffect, useState } from 'react';

const ORIENTATION = {
  LANDSCAIP: "landscaip",
  PORTRAIT: "portrait",
}

const useGetCurrentOrientation = () => {
  const [currentOrientation, setCurrentOrientation] = useState(null);

  const changeOrientation = useCallback(() => {
    const { innerWidth, innerHeight } = window;

    if (innerWidth >= innerHeight && currentOrientation !== ORIENTATION.LANDSCAIP) {
      return setCurrentOrientation(ORIENTATION.LANDSCAIP);
    }
    if (innerWidth < innerHeight && currentOrientation !== ORIENTATION.PORTRAIT) {
      return setCurrentOrientation(ORIENTATION.PORTRAIT);
    }
  }, [currentOrientation]);

  useEffect(() => {
      changeOrientation();
  }, [ changeOrientation ]);

  useEffect(() => {
      window.addEventListener('resize', changeOrientation);

      return () => {
          window.removeEventListener('resize', changeOrientation);
      }
  }, [changeOrientation]);

  return currentOrientation;
}

export default useGetCurrentOrientation;