import React from 'react';
import TagManager from 'react-gtm-module';
import { regions } from "../../content/regions.json";

import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";

import "./Regions.scss";
import Region from "../../components/Region/Region";

const tagManagerArgs = {
  gtmId: 'GTM-MXSCVWW'
};

const Regions = () => {

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <main>
      <section className={"pp-section-regions"}>
        <div className={"box"}>
          <Image className={"logo"}
                 src={"svg/logo.svg"}
                 alt={"Hyundai"} />
          <TitleBlock title={"Please select your region"} />
          <ul className={"regions"}>
            {regions.map(region => <Region key={region} region={region} />)}
          </ul>
        </div>
      </section>
    </main>
  );

};

Regions.propTypes = {};

export default Regions;
