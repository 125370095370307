import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';

import DefaultSlide from "./Slides/DefaultSlide/DefaultSlide";
import Controls from "./Controls/Controls";
import PaginationComp from "./Pagination/Pagination";
import Button from "../UI/Button/Button";
import "./slider.scss";
import Image from "../UI/Images/Image";
import DescriptionBlock from "../YoutubeSlider/DescriptionBlock";

import {Navigation, Pagination, Autoplay, EffectFade} from "swiper";

class Slider extends React.Component {

    constructor(props) {
        super(props);
        this.config = props.config || {};
        this.filterRef = React.createRef();
        this.sliderRef = React.createRef();
        this.useControls = props.useControls;
        this.usePagination = props.usePagination;
        this.autoplay = props.autoplay;
        this.effect= props.effect
        Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
        this.state = {
            slides: props.reverseOnRtl && props.dir === "rtl" ? this.reverseArray(props.slides) : props.slides,
            filtersSlides: props.filtersSlides,
            dir: props.dir
        };
    }

    componentDidMount() {
        this.slider = this.sliderRef.current;
        this.container = this.slider.querySelector(".swiper-container");
        const config = this.getConfig(this.config);
        // const config = this.config;
        this.mySwiper = new Swiper(this.container, config);
    }

    componentDidUpdate(prevProps) {
        if (this.props.slides !== prevProps.slides) {
            this.setState({slides: this.props.reverseOnRtl && this.props.dir === "rtl" ? this.reverseArray(this.props.slides) : this.props.slides}, () => {
                this.mySwiper.update();
                if (this.props.loop) {
                    this.mySwiper.loopDestroy();
                    this.mySwiper.loopCreate();
                }
                this.mySwiper.slideToLoop(0);
            });
        }
        if (this.props.dir !== prevProps.dir) {
            this.setState({dir: this.props.dir});
            setTimeout(() => {
                this.mySwiper.destroy(true);
                const config = this.getConfig(this.config);
                this.mySwiper = new Swiper(this.container, config);
                if(this.props.useDescription) this.setDescription();
                console.log('dir');
            }, 10);
        }
    }

    reverseArray(array) {
        return array.map((item, idx) => array[array.length - 1 - idx])
    }

    addZero(num){
        return (num > 9) ? num : '0' + num;
    }

    getConfig(newConf) {
        let {nextEl, prevEl, useFilter, onChange, mobileConfig, desktopConfig} = this.props;
        const {addZero} = this;
        prevEl = `.${prevEl}`;
        nextEl = `.${nextEl}`;

        let config = {
            ...newConf,
            slidesPerView: 1,
            spaceBetween: 8,
            watchOverflow: true,
            observer: true,
            observeParents: true,
            preloadImages: this.props.preloadImages || true,
            mousewheel: {
                invert: false,
                forceToAxis: true,
            },
            keyboard: {
                enabled: false,
                onlyInViewport: false,
            },
            pagination: {
                el: ".swiper-pagination",
                type: 'fraction',
                formatFractionCurrent: addZero,
                formatFractionTotal: addZero
            },
            breakpoints: {
                1023: newConf || {}
            }
        };

        config = {...config, ...desktopConfig, ...mobileConfig};

        config["breakpoints"]["768"] = this.config;

        if (config["pagination"]?.type === "bullets") {
            config["pagination"]["bulletActiveClass"] = "active";
            config["pagination"]["dynamicMainBullets"] = 4;
            config["pagination"]["dynamicBullets"] = this.props.slides.length >= 6;
            config["pagination"]["modifierClass"] = this.props.slides.length >= 6 ? "dynamic " : "";
        }

        if (this.useControls) {
            config["navigation"] = {
                nextEl,
                prevEl,
                disabledClass: "disabled",
            };
        }

        if (!this.usePagination) {
            config["breakpoints"]["768"]["pagination"] = {el: ""};
        }

        if (config["breakpoints"]["768"]["pagination"]?.type === "bullets") {
            config["breakpoints"]["768"]["pagination"]["bulletActiveClass"] = "active";
            config["breakpoints"]["768"]["pagination"]["dynamicMainBullets"] = 4;
            config["breakpoints"]["768"]["pagination"]["dynamicBullets"] = this.props.slides.length >= 4;
            config["breakpoints"]["768"]["pagination"]["modifierClass"] = this.props.slides.length >= 4 ? "dynamic " : "";
        }

        if (this.props.forceOnChange) {
            config["on"] = {
                slideChange: () => {
                    if(this.props.useDescription){
                        this.setDescription();
                    }
                    onChange(this.mySwiper.realIndex, this.state.slides[this.mySwiper.realIndex]);
                }
            }
        }
        if (useFilter) {
            config["on"] = {
                slideChange: () => {
                    onChange(this.mySwiper.realIndex);
                    this.updateFilters(this.mySwiper.realIndex);
                }
            }
        }

        return config;

    }

    renderSlides(slide, index, renderSlide, length, callback) {
        return renderSlide
            ? renderSlide(slide, index, length, callback)
            : <DefaultSlide key={index}
                            index={index}
                            slideInfo={slide}
                            length={length}
                            callback={callback}
                            counter={this.props.counter}/>
    }

    filterCallback(index) {
        this.mySwiper.slideTo(index);
        this.updateFilters(index);
    }

    updateFilters(index) {
        this.mySwiper.slideTo(index);
        this.filterRef.current.mySwiper.slideTo(index);
    }

    slideTo(index) {
        this.mySwiper.slideTo(index);
    }

    returnBtnText() {
        if (this.props.changeBtnText) {
            return this.state.slides[this.mySwiper ? this.mySwiper.activeIndex : 0].btnText;
        } else {
            return "btns.watch"
        }
    }

    returnDescription() {
        return this.state.slides[this.mySwiper ? this.mySwiper.activeIndex : 0].description;
    }

    setDescription(){
        this.setState({description: this.returnDescription()})
    }

    render() {

        const {
            className,
            renderSlide,
            controlsClass,
            nextEl,
            prevEl,
            useCustomControls,
            useFilter,
            renderFilter,
            callback,
            nextElSvg,
            prevElSvg,
            paginationClass,
            useYoutubeBtn,
            youtubeBtnClass,
            youtubeImage,
            useDescription,
        } = this.props;
        const {filtersSlides} = this.state;

        const {slides, dir} = this.state;

        return (
            <>
                <div className={`slider ${className}`} ref={this.sliderRef} dir={dir}>
                    <div className={"slider-content"}>
                        <div className={"swiper-container"}>
                            <div className={"swiper-wrapper"}>
                                {slides.map((slide, index) => this.renderSlides(slide, index, renderSlide, slides.length, callback))}
                            </div>
                            {youtubeImage && <Image className={"youtube-bg"} src={youtubeImage}/>}
                            {useYoutubeBtn &&
                            <Button text={this.returnBtnText()} className={`btn-youtube ${youtubeBtnClass}`}/>}
                            {useDescription && <DescriptionBlock useAnim={false} svgColor={"#1385B7"} dir={dir} list={this.state.description || this.returnDescription()}/>}
                            {this.useControls && !useCustomControls
                                ? <Controls className={controlsClass}
                                            direction={dir}
                                            prevEl={prevEl}
                                            nextEl={nextEl}/>
                                : null}
                            {/* <PaginationComp
                                className={`${paginationClass}  ${this.props.slides.length >= 4 ? "dynamic " : ""}`}
                                direction={dir}/> */}
                        </div>
                        {this.useControls && useCustomControls
                            ? <Controls className={controlsClass}
                                        prevEl={prevEl}
                                        nextEl={nextEl}
                                        direction={dir}
                                        prevElSvg={prevElSvg}
                                        nextElSvg={nextElSvg}
                                        useWrapper={false}/>
                            : null}
                    </div>
                </div>
                {useFilter
                    ? renderFilter(filtersSlides, this.filterCallback.bind(this), this.filterRef)
                    : null}
            </>
        )
    }

}

Slider.defaultProps = {
    className: "",
    slides: [],
    useControls: true,
    usePagination: true,
    renderSlide: null,
    controlsClass: "",
    nextEl: "swiper-button-next",
    prevEl: "swiper-button-prev",
    useCustomControls: false,
    useFilter: false,
    renderFilter: null,
    filtersSlides: () => {
    },
    autoplay: false,
    effect: null,
    callback: null,
    prevElSvg: "arrow-reverse",
    nextElSvg: "arrow",
    paginationClass: "",
    useYoutubeBtn: false,
    youtubeBtnClass: "",
    desktopConfig: {},
    youtubeImage: "",
};

Slider.propTypes = {
    className: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.object).isRequired,
    effect: PropTypes.string,
    renderSlide: PropTypes.func,
    useControls: PropTypes.bool,
    usePagination: PropTypes.bool,
    controlsClass: PropTypes.string,
    nextEl: PropTypes.string,
    prevEl: PropTypes.string,
    useCustomControls: PropTypes.bool,
    useFilter: PropTypes.bool,
    renderFilter: PropTypes.func,
    filtersSlides: PropTypes.func,
    callback: PropTypes.func,
    prevElSvg: PropTypes.string,
    nextElSvg: PropTypes.string,
    onChange: PropTypes.func,
    paginationClass: PropTypes.string,
    useYoutubeBtn: PropTypes.bool,
    youtubeBtnClass: PropTypes.string,
    youtubeImage: PropTypes.string,
    desktopConfig: PropTypes.object,
};

export default Slider;
