import './styles.scss';
import React from 'react';
import { social } from '../../content/regions.json';
import Image from "../UI/Images/Image";

const Social = ({
  region,
  lang,
  dir,
}) => {
  const socialData = social[region] || social.default;

  return (
    <ul className="social" dir={dir}>
      {socialData && socialData.map((item) => {
        return (
          <li key={item.id} className="social__item">
            <a href={item.link} className="social__link" aria-label='social link' target="_blank">
              <Image src={item.icon} alt={item.id} className="social__icon" />
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default Social;
