import React from 'react';
import PropTypes from 'prop-types';

import "./pagination.scss";

const Pagination = ({className, direction}) => {
    return (
        <div className={`pagination ${className} ${direction}`}>
            <div className={"pagination-container"}>
                <div className={"swiper-pagination"}></div>
            </div>
        </div>
    );
};

Pagination.defaultProps = {
    className: "",
};

Pagination.propTypes = {
    className: PropTypes.string,
};

export default Pagination;
