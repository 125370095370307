import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";

import "./SliderWideCard.scss";

const SliderWideCard = ({ title, subtitle, slides, dir, usePagination}) => {
    function addZero(num){
        return (num > 9) ? num : '0' + num;
      }
  return (
    <section className={`pp-section pp-section-slider-wide-cards`}>
      <div className={"container"}>
        <TitleBlock title={title}
                    subtitle={subtitle} />
        <Slider slides={slides}
                className={"visible"}
                dir={dir}
                loop={false}
                config={{ slidesPerView: 1, spaceBetween: 34, loop: false,
                  useControls: true,
                  usePagination: usePagination,
                  pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                    formatFractionCurrent: addZero,
                    formatFractionTotal: addZero
                  },
                  breakpoints: {
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 34,
                        slideToClickedSlide: true,
                    },
                    767: {
                      slidesPerView: 2,
                      spaceBetween: 34,
                      slideToClickedSlide: true,
                    },
                    1023: {
                      slidesPerView: 1,
                      spaceBetween: 34,
                    },
                  }
               }} />
      </div>
    </section>
  );

};

SliderWideCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SliderWideCard;
