import React from 'react';
import PropTypes from 'prop-types';
import Image from "../../../UI/Images/Image";
import "./ThumbSlide.scss";

const ThumbSlide = ({ className, slideInfo, index, activeIndex, callback, updateActive }) => {

  return (
    <div className={`picture-slide swiper-slide ${className} ${slideInfo.className || ''} ${activeIndex === index ? "active" : ""}`}
         style={{ ...slideInfo.style }}
         onClick={() => {
           callback(index);
           updateActive(index);
         }}>
      <div className={"wrapper"}>
        <Image src={slideInfo.image} placeholder={"placeholders/2400.jpg"}/>
      </div>
    </div>
  )
};

ThumbSlide.defaultProps = {
  className: "",
  index: 0,
  length: 0,
  callback: null,
};

ThumbSlide.propTypes = {
  slideInfo: PropTypes.object.isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  length: PropTypes.number,
  callback: PropTypes.func,
  updateActive: PropTypes.func,
};

export default ThumbSlide;
