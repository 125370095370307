import React from 'react';
import Image from "../../components/UI/Images/Image";
import ToggleLang from "../../components/ToggleLang/ToggleLang";
import "./Header.scss";
import {LanguageContext} from "../../contexts/LanguageContext";

const Header = ({region, lang, dir, opened, modelsArray, selectedIndex, setSelectedIndex}) => {

  // const menu = [
  //   {
  //     text: "N-Line",
  //     link: "#models",
  //     id: 3
  //   },
  //   {
  //     text: "Hybrid",
  //     link: "#models",
  //     id: 1
  //   },
  //   {
  //     text: "GDi",
  //     link: "#models",
  //     id: 2
  //   }

  // ]

    const [isDrop, setDrop] = React.useState(false)

  const setModel = (id) => {
    setSelectedIndex(modelsArray.find((item) => id === item.id))
    setDrop(false)
  }

  return (
    <LanguageContext.Consumer>

      {context => {
        return (
          <header dir={'ltr'}>
            <div className={"container"}>
              {window.innerWidth < 767 && <div  className={`menu-mobile`}>

                <button onClick={() => setDrop(!isDrop)} className={"mobile-item"}>
                  <span ><span dir={"ltr"}>{selectedIndex.text}</span>
                    <svg className={`${isDrop ? "active" : ""}`} width="10" height="6" viewBox="0 0 10 6" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.71429 6L0 0H10L5.71429 6Z" fill="black"/>
                    </svg>
                  </span>
                </button>

                <div className={`drop-list ${isDrop ? "active" : ""}`}>
                    {
                        modelsArray.map((item, i) => {
                            if(item.id !== selectedIndex.id) {
                                return <button key={`drop-list-${i}`} dir={"ltr"} onClick={() => setModel(item.id)}
                                               className={`mobile-item ${selectedIndex.id === item.id ? "active" : ""}`}>
                                    {item.text}
                                </button>
                            }
                        } )
                    }
                </div>
              </div>
              }
              <div className={"menu"}>
                <Image src={[{src: "svg/logo.svg", media: ""}, {
                  src: "svg/logo-small.svg",
                  media: "768px"
                }]}
                       alt={"Hyundai"}
                       className={"logo"}/>
                {window.innerWidth > 767 && <nav dir={dir} className={"menu-list"}>
                  {
                    modelsArray.map((item, i) => {
                      return <button key={`menu-list-${i}`} dir={"ltr"} onClick={() => {
                        setModel(item.id)
                      }}
                                     className={`menu-item ${selectedIndex.id === item.id && "active"}`}
                      >
                        {item.text}
                      </button>
                    })
                  }
                </nav>}
              </div>
              {/* <Image src={"/icons/top.svg"} className={"top-button desktop"} onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              }}/> */}
              <ToggleLang className={""} oneString={true} arText={'العربية'} enText={'English'} />
            </div>
          </header>
        );
      }}
    </LanguageContext.Consumer>
  );

};

Header.propTypes = {};

export default Header;
