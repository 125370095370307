import React from 'react';
import {LanguageContext} from "../../contexts/LanguageContext";
import Parser from "html-react-parser";

import "./More.scss";

export const More = () => {
  return (
    <LanguageContext.Consumer>

      {context => {

        return (

          <div className={"more"}>{context.getTranslate("more").map(item =>
            <p>
              {Parser(item)}
            </p>)}
          </div>)
      }}
    </LanguageContext.Consumer>
  )};
