import React from 'react';
import PropTypes from 'prop-types';
import Image from "../../../UI/Images/Image";
import "./PictureSlide.scss";
import {LanguageContext} from "../../../../contexts/LanguageContext";

const PictureSlide = ({ className, slideInfo, index, length, useCounter, placeholder }) => {

  return (
      <LanguageContext.Consumer>
        {context => {return (
    <div className={`picture-slide swiper-slide ${className} ${slideInfo.className || ''}`}
         style={{ ...slideInfo.style }}>
      <div className={"wrapper"}>
        {useCounter ? <div className={"counter"}>{index + 1} {context.getTranslate("other.of")} {length}</div> : null}
        <Image src={slideInfo.image} placeholder={`placeholders/${placeholder || "1120"}.jpg`}/>
      </div>
    </div>
  )}}
      </LanguageContext.Consumer>
  )
};

PictureSlide.defaultProps = {
  className: "",
  index: 0,
  length: 0,
  useCounter: false,
};

PictureSlide.propTypes = {
  slideInfo: PropTypes.object.isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  length: PropTypes.number,
  useCounter: PropTypes.number,
};

export default PictureSlide;
